.header-bar {
  .header-ul {
    display: flex;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      color: #fff;
      padding: 0;
      margin: 0;
      list-style: none;
      height: 64px;
      line-height: 64px;
      margin-right: 20px;
    }
  }

  .menu-icon {
    position: absolute;
    right: 23px;
    font-size: 20px;
    top: 22px;
    color: #f5f5f7;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

  }
}

@primary-color: #f5f5f7;@text-selection-bg: rgba(0, 0, 0, 0.8);