body {
  height: 100%;
  margin: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #f5f5f7;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

@keyframes lds-1 {
  0% {
    top: 25px;
    height: 160px;
  }
  50% {
    top: 66px;
    height: 80px;
  }
  100% {
    top: 66px;
    height: 80px;
  }
}
@keyframes lds-2 {
  0% {
    top: 35px;
    height: 142px;
  }
  50% {
    top: 66px;
    height: 81px;
  }
  100% {
    top: 66px;
    height: 81px;
  }
}
@keyframes lds-3 {
  0% {
    top: 46px;
    height: 120px;
  }
  50% {
    top: 66px;
    height: 81px;
  }
  100% {
    top: 66px;
    height: 81px;
  }
}

.loading__box {
  display: flex;
  align-items: center;
  justify-content: center;
  &-lds {
    width: 200px;
    height: 200px;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    div {
      position: absolute;
      box-sizing: content-box;
      width: 20px;
      &:nth-child(1) {
        left: 35px;
        background: #85a2b6;
        animation: lds-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        animation-delay: -0.2s;
      }
      &:nth-child(2) {
        left: 85px;
        background: #bbcedd;
        animation: lds-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        animation-delay: -0.1s;
      }
      &:nth-child(3) {
        left: 135px;
        background: #dce4eb;
        animation: lds-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
        animation-delay: undefineds;
      }
    }
  }
}

#root {
  height: 100%;
}

@primary-color: #f5f5f7;@text-selection-bg: rgba(0, 0, 0, 0.8);