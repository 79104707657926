.logo {
  float: left;
  width: 200px;
  height: 64px;
  line-height: 64px;
  color: #fff;
  font-size: 24px;
}

.header {
  background: rgba(0,0,0,0.8);
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.tools-layout {
  height: 100%;

  &-content {
    margin-top: 64px;
  }

  &-container {
    padding: 0 24px 24px;

    &-breadcrumd {
      margin: 16px 0;
    }

    &-footer {
      text-align: center;
      position: fixed;
      z-index: 99;
      bottom: 0;
      width: 100%;
    }
  }
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  margin: 0;
  padding: 0;
  height: 100%;
}

#subapp-container {
  padding-top: 64px;
  padding-bottom: 70px;
  min-height: 100%;
}

#subapp-viewport {
  height: 100%;
}

@primary-color: #f5f5f7;@text-selection-bg: rgba(0, 0, 0, 0.8);